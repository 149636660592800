import * as React from "react";
import { HeadFC, Link, navigate } from "gatsby";
import { CustomHead } from "../../components/shell/CustomHead";
import Shell from "../../components/shell/Shell";
import EmailForm from "../../components/forms/EmailForm";
import { Id, toast, TypeOptions } from "react-toastify";
import { useFirebaseService } from "../../core/contexts/firebase";

const pageDescription =
  "Don't worry! It happens. Enter the email address associated with your account and we will send you an email with instructions to reset your password.";

const ForgotPasswordPage = ({ location }: any) => {
  const firebaseService = useFirebaseService();
  const [email, setEmail] = React.useState(location.state?.email ?? "");
  const isInstructionsSent = location.hash === "#success";

  if (isInstructionsSent && !location.state?.email) {
    let to = "/auth/forgot-password";
    let options = {};
    if (email) {
      to = `#success`;
      options = { state: { email } };
    }
    navigate(to, { ...options, replace: true });
  }

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  const onSendInstructions = async (isValid: boolean) => {
    if (isValid && firebaseService) {
      makeToast("Sending password reset instructions", toast.TYPE.INFO, false);
      try {
        await firebaseService.doPasswordReset(email);
        const message =
          "Instructions sent successfully. Please check your inbox.";
        makeToast(message, toast.TYPE.SUCCESS);
        navigate("#success", { state: { email } });
        toastId.current = null;
      } catch (error) {
        console.log(error);
        const message =
          "Something went wrong while sending instructions, please try again later";
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <Shell>
      <div className="overflow-hidden">
        <div className="relative mx-auto max-w-7xl py-32 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
            <div>
              <p className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                {isInstructionsSent
                  ? "Check your email"
                  : "Forgot your password?"}
              </p>
              <p className="mt-5 max-w-5xl text-xl text-gray-500">
                {isInstructionsSent ? (
                  <span>
                    We have sent password reset instructions to{" "}
                    <strong>{location.state?.email}</strong>.
                  </span>
                ) : (
                  pageDescription
                )}
              </p>
            </div>
            {!isInstructionsSent && (
              <EmailForm
                label="Send instructions"
                email={email}
                onEmailChanged={setEmail}
                onButtonClick={onSendInstructions}
              />
            )}
          </div>
          {isInstructionsSent && (
            <div className="mt-24">
              <p>
                Did not receive the email? Check your spam filter, or{" "}
                <Link
                  className="nline-flex items-center text-base font-medium leading-4 text-indigo-600 hover:text-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  to="/auth/forgot-password"
                  replace
                >
                  try another email address
                </Link>
                .
              </p>
            </div>
          )}
        </div>
      </div>
    </Shell>
  );
};

export default ForgotPasswordPage;

export const Head: HeadFC = () => (
  <CustomHead title="Forgot Password" description={pageDescription} />
);
