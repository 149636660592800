import * as React from "react";

export interface EmailFieldProps {
  email: string;
  disabled?: boolean;
  trailing?: React.ReactNode;
  onChange: (email: string) => void;
}

const EmailField = ({
  email,
  disabled = false,
  trailing,
  onChange,
}: EmailFieldProps) => {
  return (
    <div className="space-y-1">
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        Email address
      </label>
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          disabled={disabled}
          value={email}
          onChange={(e) => onChange(e.target.value)}
          required
          className={`${
            trailing ? "rounded-none rounded-l-md" : "rounded-md"
          } block w-full appearance-none border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
        />
        {trailing}
      </div>
    </div>
  );
};

export default EmailField;
